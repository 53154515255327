<template>
  <section>
    <SeperationBox>
      <template>
        {{ currentModel && currentModel.name }}
      </template>
      <template v-slot:custom>
        <div
          class="hidden lg:inline-flex items-center w-1/3 xl:w-1/2 px-4 md:px-6 lg:px-3 uppercase text-xs font-semibold tracking-widest"
        >
          {{ $t('productsWithModel') }}
        </div>
      </template>
    </SeperationBox>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:gap-3">
      <ModelDetailInfo ref="modelDetailInfo" class="md:col-span-2 md:row-span-2" />
      <SeperationBox class="lg:hidden">
        <template>
          {{ $t('productsWithModel') }}
        </template>
      </SeperationBox>
      <ProductListItem
        v-for="product in allModelProducts"
        :key="product.id"
        :to="`/product/${product.id}`"
        :title="product.title"
        :type="product.type"
        :price="product.credits"
        :thumbnail="getThumbnail(product)"
        :availability="product.accessStatus"
        :pinned="product.pinned"
        hide-indicators
        :active-subscription="activeSubscription"
      />
    </div>
    <div ref="loadMoreTarget" />
    <div class="flex w-full justify-center py-4">
      <r-loading-circle v-show="modelProductsLoading" class="w-8 h-8" />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { OPEN_MODEL } from '@/store/storeTypes/mutationTypes';
import { ModelDetailInfo, ProductListItem, SeperationBox } from '../components';

export default {
  components: {
    ModelDetailInfo,
    ProductListItem,
    SeperationBox,
  },

  data() {
    return {
      observer: null,
    };
  },

  computed: {
    ...mapGetters([
      'currentModel',
      'modelsLoading',
      'allModelProducts',
      'modelProductsLoading',
      'activeSubscription',
    ]),
    productsQuery() {
      return {
        $joinRelation: 'models',
        $eager: {
          files: { types: true },
        },
        type: {
          $ne: 'bundle',
        },
        editing: false,
        visable: true,
        status: 'processed',
        'models.id': Number(this.$route.params.id),
        $sort: {
          createdAt: -1,
        },
        onlyThumbnails: true,
      };
    },
  },

  watch: {
    $route: {
      immediate: true,
      async handler(newVal) {
        const id = Number(newVal.params.id);
        this[OPEN_MODEL](id);
        if (!this.currentModel) {
          this.getModel({
            id,
          });
        }
        await this.findModelProducts({
          query: this.productsQuery,
          refresh: true,
        });
        this.initOberserver();
      },
    },
  },
  destroyed() {
    this.observer.disconnect();
  },

  methods: {
    ...mapMutations([OPEN_MODEL]),
    ...mapActions(['getModel', 'findModelProducts']),
    initOberserver() {
      this.observer = new IntersectionObserver(([entry]) => {
        if (!this.modelProductsLoading && entry && entry.isIntersecting) {
          this.findModelProducts({
            query: this.productsQuery,
            refresh: false,
          });
        }
      });

      this.observer.observe(this.$refs.loadMoreTarget);
    },
    getThumbnail(product) {
      let { files } = product;
      if (product.type === 'bundle') {
        files = product.bundleProducts[0].files;
      }

      const thumbnailFile = files.find(
        (file) => file.types && file.types.some((type) => type.name === 'thumbnail'),
      );
      return thumbnailFile ? thumbnailFile.url : '';
    },
  },
};
</script>
