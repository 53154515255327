<template>
  <section class="col-span-4">
    <SeperationBox>
      <template v-if="currentFilter === 'my'">
        {{ $t('videoFilter.my') }}
      </template>
      <template v-else-if="currentFilter === 'category'">
        {{ currentCategory.name }}
      </template>
      <template v-else-if="currentFilter === 'favorites'">
        {{ $t('favorites') }}
      </template>
      <template v-else-if="currentFilter === null">
        {{ $t('allProducts') }}
      </template>
      <template v-if="currentFilter !== 'my'" v-slot:right>
        <ProductAvailabilities />
      </template>
    </SeperationBox>
    <ProductList
      :products="filteredProducts"
      :active-subscription="activeSubscription"
      :current-user="currentUser"
      :loading="productsLoading"
      @load-more="lazyLoadProducts"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { OPEN_CATEGORY, SET_PRODUCTS_VIEW_INFO } from '@/store/storeTypes/mutationTypes';
import { SeperationBox, ProductList, ProductAvailabilities } from '@/ui/components';

export default {
  components: {
    SeperationBox,
    ProductList,
    ProductAvailabilities,
  },

  computed: {
    ...mapGetters([
      'activeSubscription',
      'allProducts',
      'productsLoading',
      'currentCategory',
      'lastProductsView',
      'currentUser',
      'lastVisitedPage',
      'currentUser',
      'productsCurrentLimit',
    ]),
    filteredProducts() {
      const filteredProducts = this.allProducts.filter((product) => {
        if (product.type !== 'bundle') return true;
        return !product.bundleProducts.every((bundleProduct) =>
          bundleProduct.purchases.some((purchase) => purchase.userId === this.currentUser.id),
        );
      });

      return filteredProducts;
    },
    currentFilter() {
      return this.$route.params.filter || null;
    },
    currentIdParam() {
      if (this.$route.params.id) return this.$route.params.id;
      return null;
    },
    query() {
      const newQuery = {
        $eager: {
          files: { types: true },
          bundles: {
            bundleProducts: {
              files: { types: true },
              purchases: true,
            },
          },
          bundleProducts: {
            files: { types: true },
            purchases: true,
          },
        },
        $sort: {
          pinned: -1,
          createdAt: -1,
        },
        $or: [
          {
            editing: 0,
            status: 'processed',
          },
          {
            editing: 'null',
            status: 'null',
          },
        ],
        visable: 1,
        onlyThumbnails: true,
      };

      if (this.currentFilter === 'search' && this.currentIdParam) {
        if (this.$route.query.filter === 'Tags') {
          newQuery.keywords = { $like: `%${this.currentIdParam}%` };
        } else {
          newQuery.title = { $like: `%${this.currentIdParam}%` };
        }
      } else if (this.currentFilter === 'my') {
        newQuery.$joinRelation = 'purchases';
      } else if (this.currentFilter === 'category') {
        newQuery.$joinRelation = 'categories';
        newQuery['categories.id'] = Number(this.$route.params.id);
      } else if (this.currentFilter === 'favorites') {
        newQuery.$joinRelation = 'favorites';
      }
      return newQuery;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.currentFilter === 'category') {
          this[OPEN_CATEGORY](Number(this.$route.params.id));
        } else {
          this[OPEN_CATEGORY](null);
        }

        if (
          this.lastProductsView.lastFilter !== this.currentFilter ||
          this.lastProductsView.lastIdParam !== this.currentIdParam ||
          this.allProducts.length < this.productsCurrentLimit ||
          !this.lastVisitedPage.startsWith('/product/')
        ) {
          this.findProducts({
            query: this.query,
            refresh: true,
          });
          this.setViewInfo(0);
        } else {
          this.scrollToLastPosition();
        }
      },
    },
  },

  methods: {
    ...mapActions(['findProducts']),
    ...mapMutations([OPEN_CATEGORY, SET_PRODUCTS_VIEW_INFO]),
    lazyLoadProducts() {
      this.findProducts({
        query: this.query,
        refresh: false,
      });
    },
    setViewInfo(scrollY = 0) {
      this[SET_PRODUCTS_VIEW_INFO]({
        lastFilter: this.currentFilter,
        lastIdParam: this.currentIdParam,
        scrollY,
      });
    },
    scrollToLastPosition() {
      window.scrollTo(0, this.lastProductsView.scrollY);
    },
  },
};
</script>
