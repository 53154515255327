<template>
  <section>
    <SeperationBox>
      <template>
        {{ $t('addCredits') }}
      </template>
    </SeperationBox>
    <div class="grid grid-cols-10 gap-1 lg:gap-3">
      <GoldCardUpload />
      <GoldCardLink />
      <GoldCardCMS />
    </div>
  </section>
</template>

<script>
import { SeperationBox, GoldCardUpload, GoldCardLink, GoldCardCMS } from '@/ui/components';

export default {
  components: {
    SeperationBox,
    GoldCardUpload,
    GoldCardLink,
    GoldCardCMS,
  },

  data() {
    return {};
  },
};
</script>
