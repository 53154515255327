<template>
  <section>
    <SeperationBox>
      <template>
        {{ $t('myAccount') }}
      </template>
    </SeperationBox>
    <div class="grid grid-cols-2 gap-3">
      <MyAccountCredentials />
      <MyAccountDeletion />
    </div>
  </section>
</template>

<script>
import { SeperationBox, MyAccountCredentials, MyAccountDeletion } from '@/ui/components';

export default {
  components: {
    SeperationBox,
    MyAccountCredentials,
    MyAccountDeletion,
  },
};
</script>
