<template>
  <div>
    <form v-if="!resettedPassword" @submit.prevent="onResetPassword">
      <div :class="fieldStyles">
        <label for="password-input" :class="labelStyles">{{ $t('password') }}</label>
        <input
          id="password-input"
          v-model="newPassword"
          type="password"
          :class="inputStyles"
          :placeholder="$t('password')"
          minlength="6"
          required
          @focus="passwordFieldIsFocused = true"
          @blur="passwordFieldIsFocused = false"
        >
      </div>
      <div :class="fieldStyles">
        <label 
          for="password-repeat-input" 
          :class="labelStyles"
        >
          {{ $t('repeatPassword') }}
        </label>
        <input
          id="password-repeat-input"
          v-model="newPasswordRepeat"
          type="password"
          :class="inputStyles"
          :placeholder="$t('repeatPassword')"
          required
          minlength="6"
          @focus="passwordFieldIsFocused = true"
          @blur="passwordFieldIsFocused = false"
        >
      </div>

      <transition name="fade">
        <div 
          v-if="
            newPassword !== newPasswordRepeat && 
              newPasswordRepeat && newPassword &&
              !passwordFieldIsFocused
          " 
          class="text-red-600 text-xs"
        >
          {{ $t('invalidPasswordRepeat') }}
        </div>
        <div v-if="error" class="text-red-600 text-xs">
          {{ $t('resetPasswordExpired') }}
        </div>
      </transition>

      <div class="mt-1">
        <button
          type="submit"
          class="uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer"
        >
          {{ $t('resetPassword') }}
        </button>
      </div>
      <router-link
        to="/login"
        class="text-xs inline-block mt-4 cursor-pointer line hover:opacity-75"
      >
        {{ $t('backToLogin') }}
      </router-link>
    </form>

    <transition name="fade">
      <div v-if="resettedPassword">
        <div class="text-green-600 text-xs">
          {{ $t('passwordWasResetted') }}
        </div>

        <router-link
          to="/login"
          class="text-xs inline-block mt-3 cursor-pointer line hover:opacity-75"
        >
          {{ $t('backToLogin') }}
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { accountsApi } from '@/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => {
    return {
      newPassword: '',
      newPasswordRepeat: '',
      passwordFieldIsFocused: false,
      error: false,
      resettedPassword: false,
      fieldStyles: 'pb-3',
      labelStyles: 'pl-2 block uppercase text-xxs tracking-wide',
      inputStyles: 'w-full bg-text py-1 pl-2 text-black text-base lg:text-xs',
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.currentUser) {
          this.logout();
        }
      },
    },
  },

  methods: {
    ...mapActions(['logout']),
    async onResetPassword() {
      if (this.newPassword === this.newPasswordRepeat) {
        try {
          await accountsApi.resetPassword(this.$route.params.token, this.newPassword);
          this.resettedPassword = true;
        } catch (err) {
          this.error = true;
        }
      }
    },
  },
};
</script>
