<template>
  <section class="col-span-4">
    <SeperationBox>
      <template>
        {{ $tc('model', 2) }}
      </template>
    </SeperationBox>
    <ModelList :models="allModels" :loading="modelsLoading" @load-more="lazyLoadModels" />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_MODELS_VIEW_INFO } from '@/store/storeTypes/mutationTypes';
import { SeperationBox, ModelList } from '@/ui/components';

export default {
  components: {
    SeperationBox,
    ModelList,
  },

  computed: {
    ...mapGetters(['allModels', 'modelsLoading', 'lastModelsView', 'modelsCurrentLimit']),
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.allModels.length < this.modelsCurrentLimit) {
          this.findModels({
            refresh: true,
            query: {
              $sort: {
                name: 1,
              },
            },
          });
          this.setViewInfo(0);
        } else {
          this.scrollToLastPosition();
        }
      },
    },
  },

  methods: {
    ...mapActions(['findModels']),
    ...mapMutations([SET_MODELS_VIEW_INFO]),
    lazyLoadModels() {
      this.findModels({
        refresh: false,
        query: {
          $sort: {
            name: 1,
          },
        },
      });
    },
    setViewInfo(scrollY = 0) {
      this[SET_MODELS_VIEW_INFO]({
        scrollY,
      });
    },
    scrollToLastPosition() {
      window.scrollTo(0, this.lastModelsView.scrollY);
    },
  },
};
</script>
