<template>
  <section>
    <SeperationBox>
      <template>
        {{ $t('addCredits') }}
      </template>
    </SeperationBox>
    <Stepper v-model="currentStepperStep" :stepper-steps="stepperSteps">
      <div v-if="!loading">
        <div 
          v-if="currentStepperStep === 0" 
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 lg:gap-3"
        >
          <div 
            v-for="(creditPackage, i) in creditPackages" 
            :key="`credit-package${i}`"
            class="text-center bg-interface text-text py-6"
          >
            <div class="uppercase font-extrabold tracking-wide text-xl">
              {{ creditPackage.title }}: <br>
              <span v-if="creditPackage.prevCredits" class="line-through pr-1">
                {{ creditPackage.prevCredits }}</span>
              <span :class="{ 'text-akzent': creditPackage.prevCredits !== undefined }">
                {{ creditPackage.credits }} 
              </span>{{ $t('credits') }}
            </div>
            <div class="text-sm opacity-75 my-2">
              <div>
                <span v-if="creditPackage.prevPrice" class="line-through pr-1">
                  {{ creditPackage.prevPrice }}€</span>
                <span :class="{ 'text-akzent': creditPackage.prevPrice !== undefined }">
                  {{ creditPackage.price }}€
                </span>
              </div>
              <div>
                ({{ (creditPackage.credits / creditPackage.price).toFixed(2) }} 
                {{ $t('credits') }} / €)
              </div>
              <div>
                {{ $t('discount') }} 
                {{ 
                  (((creditPackage.credits - creditPackage.price * 10) 
                    / (creditPackage.price * 10)) * 100).toFixed(1) 
                }}%
              </div>
            </div>
            <Button class="my-2" @click="selectCreditPackage(i)"> 
              {{ $t('nextStep') }}
            </Button>
          </div>
        </div>

        <form 
          v-if="currentStepperStep === 1" 
          class="grid md:grid-cols-2 lg:grid-cols-4 gap-1 lg:gap-3"
          @submit.prevent="createPayment"
        >
          <div class="md:col-span-2 lg:col-span-4 text-sm p-3 lg:py-0">
            <div>{{ $t('paymentNote') }}</div>
            <label class="custom-checkbox mt-2">
              <span>{{ $t('acceptThe') }} </span>
              <span 
                class="underline cursor-pointer" 
                @click="$event.preventDefault(); showAgbOverlay = true"
              >
                {{ $t('termsAndCondtions') }}
              </span>

              <input v-model="acceptedAgb" type="checkbox" required>
              <span 
                class="checkmark"
                @click="acceptedAgb = !!acceptedAgb "
              />
            </label>
          </div>
          <div 
            class="text-center bg-interface text-text py-5"
          >
            <Button class="my-2" type="submit">
              {{ $t('buyNow') }}
            </Button>
            <div class="hidden">
              <form
                ref="maxpay-form"
                action="https://hpp.maxpay.com/hpp"
                class="redirect_form"
                method="post"
              >
                <div v-for="(value, name) in paymentForm" :key="name">
                  <input type="hidden" :name="name" :value="value">
                </div>
                <input type="hidden" name="signature" :value="paymentFormSignature">
              </form>
            </div>
          </div>
        </form>
        <div 
          v-if="currentStepperStep === 2"
          class="grid md:grid-cols-2 lg:grid-cols-4 gap-1 lg:gap-3"
        >
          <div 
            class="text-sm text-center bg-interface p-5"
          >
            <span v-if="lastPayment.status === 'completed'"> {{ $t('successfulPayment') }}</span>
            <span v-if="lastPayment.status === 'failed'"> {{ $t('failedPayment') }}</span>
          </div>
        </div>
      </div>
    </Stepper>
    <Overlay v-model="showAgbOverlay" close-icon>
      <div class="max-w-4xl cms-content h-screen overflow-y-auto">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="agbContent" />
      </div>
    </Overlay>
  </section>
</template>

<script>
import { SeperationBox } from '@/ui/components';
import { paymentsApi } from '@/api';

export default {
  components: {
    SeperationBox,
  },

  data() {
    return {
      loading: false,
      stepperSteps: [
        {
          title: 'chooseCreditPackage',
          clickable: true,
        },
        {
          title: 'payment',
        },
        {
          title: 'done',
          spanCol: true,
        },
      ],
      currentStepperStep: 0,
      creditPackages: [
        {
          title: 'BASIC',
          credits: 150,
          price: 15,
        },
        {
          title: 'MEDIUM',
          credits: 300,
          price: 29,
        },
        {
          title: 'ADVANCED',
          credits: 600,
          price: 55,
        },
        {
          title: 'AMBITIOUS',
          credits: 1000,
          price: 90,
        },
        {
          title: 'PRO',
          credits: 1700,
          price: 150,
        },
        {
          title: 'ULTRA',
          credits: 2500,
          price: 220,
          prevPrice: 235,
        },
        {
          title: 'KILLER',
          credits: 5250,
          prevCredits: 5000,
          price: 450,
        },
      ],
      selectedCreditPackage: null,
      showAgbOverlay: false,
      acceptedAgb: false,
      agbContent: null,
      paymentForm: {},
      paymentFormSignature: '',
      lastPayment: {},
      paymentRequestLoading: false,
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.params.slug === 'redirect') {
          this.loading = true;
          const payments = await paymentsApi.findPayments({
            query: {
              $sort: {
                createdAt: -1,
              },
              $limit: 1,
            },
          });
          [this.lastPayment] = payments.data;
          if (this.lastPayment?.status !== 'pending') {
            this.currentStepperStep = 2;
          } else {
            this.currentStepperStep = 0;
          }

          this.loading = false;
        }
      },
      deep: true,
    },
  },

  async created() {
    const resp = await this.$axios(
      `${process.env.VUE_APP_DIRECTUS_URL}/items/agb?fields=*,translations.*&filter[active]=true`,
    );
    this.agbContent = resp.data.data[0].translations.find((translation) =>
      translation.languages_code.startsWith(this.$i18n.locale),
    ).content;
  },

  methods: {
    selectCreditPackage(index) {
      this.selectedCreditPackage = index;
      this.currentStepperStep = 1;
    },
    async createPayment() {
      if (this.acceptedAgb && !this.paymentRequestLoading) {
        this.paymentRequestLoading = true;
        this.paymentForm = {};
        this.paymentFormSignature = '';

        const resp = await paymentsApi.createPayment({
          creditPackage: this.selectedCreditPackage + 1,
        });

        this.paymentForm = resp.paymentForm;
        this.paymentFormSignature = resp.paymentFormSignature;

        this.$nextTick(() => {
          this.$refs['maxpay-form'].submit();
          this.paymentRequestLoading = false;
        });
      }
    },
  },
};
</script>
