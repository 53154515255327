<template>
  <!-- eslint-disable vue/no-v-html -->
  <section>
    <SeperationBox>
      <template>
        {{ $t('verifyAge') }}
      </template>
    </SeperationBox>
    <div class="grid grid-cols-2">
      <div class="col-span-2 lg:col-span-1 bg-interface text-xs">
        <div class="p-6">
          <div>{{ $t('ageVerificationInstructions') }}</div>

          <div class="text-xs uppercase font-semibold tracking-widest pt-3 py-1">
            {{ $t('sample') }}:
          </div>
          <img src="../../assets/id-censored.png" class="w-full h-auto">
          
          <div class="pt-3" v-html="$t('ageVerificationInstructions2')" />
          
          <div v-if="!uploading && !submitted && !hasPendingAgeVerification">
            <input
              ref="imageUploader"
              class="hidden"
              type="file"
              accept="image/jpeg, image/png, image/gif"
              @change="onFileChanged"
            >
            <div class="flex items-center mt-3 cursor-pointer" @click="$refs.imageUploader.click()">
              <span>{{ $t('uploadDocument') }}:</span>
              <Icon
                name="attachment"
                outline
                :size="24"
                :stroke-width="'2'"
                class="ml-2"
              />
              <span class="ml-2">(max. 10MB, jpg, png, gif)</span>
            </div>
            <transition name="fade">
              <div v-if="invalidFileType" class="text-red-600 mt-3">
                {{ $t('invalidFileType') }}
              </div>
              <div v-if="selectedFile">{{ $t('choosenFile') }}: {{ selectedFile.name }}</div>
            </transition>
            <div
              class="mt-3 uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer"
              @click="submitAgeVerification"
            >
              {{ $t('submit') }}
            </div>
          </div>
          
          <div v-if="uploading" class="mt-6">
            <LoadingCircle :custom-text="true">
              {{ $t('fileUploading') }}
            </LoadingCircle>
          </div>

          <div v-if="submitted || hasPendingAgeVerification" class="mt-3 text-green-600">
            {{ $t('ageVerificationSubmitted') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SeperationBox } from '@/ui/components';
import { ageVerificationApi } from '@/api';
import { mapGetters, mapMutations } from 'vuex';
import { SET_PENDING_AGE_VERIFICATION } from '@/store/storeTypes/mutationTypes';

export default {
  components: {
    SeperationBox,
  },

  data() {
    return {
      invalidFileType: false,
      selectedFile: null,
      uploading: false,
      submitted: false,
    };
  },

  computed: {
    ...mapGetters(['hasPendingAgeVerification']),
  },

  methods: {
    ...mapMutations([SET_PENDING_AGE_VERIFICATION]),
    onFileChanged(e) {
      const file = e.target.files[0];

      if (file) {
        this.invalidFileType = false;
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
          this.$nextTick(() => {
            this.invalidFileType = true;
          });
          return;
        }
        this.selectedFile = file;
      }
    },
    async submitAgeVerification() {
      if (this.selectedFile && !this.invalidFileType && !this.uploading) {
        this.uploading = true;
        const ageVerification = await ageVerificationApi.createAgeVerification(
          this.selectedFile.type,
        );

        const formData = new FormData();
        Object.keys(ageVerification.image.fields).forEach((key) => {
          formData.append(key, ageVerification.image.fields[key]);
        });

        formData.append('file', this.selectedFile);

        await this.$axios.post(ageVerification.image.url, formData);
        await ageVerificationApi.patchAgeVerification(ageVerification.id, { uploaded: true });
        this.uploading = false;
        this.submitted = true;
        this[SET_PENDING_AGE_VERIFICATION](true);
      }
    },
  },
};
</script>
