<template>
  <section>
    <ChooseSubscriptionPlanDaily v-if="!activeSubscription" />
    <ActiveSubscriptionDaily v-else /> 
  </section>
</template>

<script>
import { ChooseSubscriptionPlanDaily, ActiveSubscriptionDaily } from '@/ui/components';
import { mapGetters } from 'vuex';

export default {
  components: {
    ChooseSubscriptionPlanDaily,
    ActiveSubscriptionDaily,
  },

  computed: {
    ...mapGetters(['activeSubscription']),
  },
};
</script>
