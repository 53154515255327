<template>
  <div>
    <form v-if="!emailChanged" @submit.prevent="onEmailChange">
      <div :class="fieldStyles">
        <label for="email-input" :class="labelStyles">{{ $t('email') }}</label>
        <input
          id="email-input"
          v-model="email"
          type="email"
          :class="inputStyles"
          :placeholder="$t('email')"
          required
          @focus="emailFieldIsFocused = true"
          @blur="emailFieldIsFocused = false"
        >
      </div> 
      <div :class="fieldStyles">
        <label for="repeat-email-input" :class="labelStyles">{{ $t('repeatEmail') }}</label>
        <input
          id="repeat-email-input"
          v-model="repeatEmail"
          type="email"
          :class="inputStyles"
          :placeholder="$t('email')"
          required
          @focus="emailFieldIsFocused = true"
          @blur="emailFieldIsFocused = false"
        >
      </div> 

      <transition name="fade">
        <div 
          v-if="
            email !== repeatEmail && 
              repeatEmail && email &&
              !emailFieldIsFocused
          " 
          class="text-red-600 text-xs"
        >
          {{ $t('invalidEmailRepeat') }}
        </div>
        <div v-if="error" class="text-red-600 text-xs">
          {{ $t('failedEmailChange') }}
        </div>
      </transition>
          
      <button
        type="submit"
        class="mt-3 uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer"
      >
        {{ $t('changeEmail') }}
      </button>
      <div>
        <router-link
          to="/login"
          class="text-xs inline-block mt-4 cursor-pointer line hover:opacity-75"
        >
          {{ $t('backToLogin') }}
        </router-link>
      </div>
    </form>

    <transition name="fade">
      <div v-if="emailChanged">
        <div class="text-green-600 text-xs">
          {{ $t('emailWasChanged') }}
        </div>

        <router-link
          to="/login"
          class="text-xs inline-block mt-3 cursor-pointer line hover:opacity-75"
        >
          {{ $t('backToLogin') }}
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { accountsApi } from '@/api';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      repeatEmail: '',
      emailFieldIsFocused: false,
      error: false,
      emailChanged: false,
      fieldStyles: 'pb-3',
      labelStyles: 'pl-2 block uppercase text-xxs tracking-wide',
      inputStyles: 'w-full bg-text py-1 pl-2 text-black text-base lg:text-xs',
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.currentUser) {
          this.logout();
        }
      },
    },
  },

  methods: {
    ...mapActions(['logout']),
    async onEmailChange() {
      if (this.email === this.repeatEmail) {
        try {
          await accountsApi.changeEmail(this.$route.params.token, this.email);
          this.emailChanged = true;
        } catch (err) {
          this.error = true;
        }
      }
    },
  },
};
</script>
