<template>
  <section>
    <SeperationBox>
      <template>
        {{ $t('verifyEmail') }}
      </template>
    </SeperationBox>
    <div class="grid grid-cols-2">
      <div class="col-span-2 lg:col-span-1 bg-interface p-6">
        <div class="text-sm whitespace-pre-line">
          {{ $t('verifyEmailDesc') }}
        </div>
        
        
        <transition name="fade" mode="out-in">
          <div
            v-if="!emailSubmitted"
            key="submit"
            class="mt-3 uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer"
            @click="resendVerificationEmail"
          >
            {{ $t('resendEmail') }}
          </div>
        
          <div v-else key="text" class="text-xs text-green-600 mt-3">
            {{ $t('resendedVerificationEmail') }}
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import { SeperationBox } from '@/ui/components';
import { accountsApi } from '@/api';
import { mapGetters } from 'vuex';

export default {
  components: {
    SeperationBox,
  },

  data() {
    return {
      emailSubmitted: false,
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  methods: {
    async resendVerificationEmail() {
      await accountsApi.resendVerificationMail(this.currentUser.email);
      this.emailSubmitted = true;
    },
  },
};
</script>
