<template>
  <div v-if="!productsLoading">
    <section 
      v-if="
        !currentProduct.editing && 
          currentProduct.status === 'processed' && 
          currentProduct.type !== 'bundle'"
    >
      <SeperationBox>
        <template>
          <span v-if="!isMobile">
            {{ $t('productDetails') }}
          </span>
          <span v-else>
            {{ currentProduct.title }}
          </span>
        </template>
        <template v-slot:custom>
          <div 
            class="
              hidden 
              xl:inline-flex 
              items-center 
              lg:w-1/4 
              px-4 
              md:px-6 
              lg:px-3 
              uppercase 
              text-xs 
              font-semibold 
              tracking-widest
            "
          >
            {{ $t('modelsInThisVideo') }}
          </div>
        </template>
      </SeperationBox>

      <div class="grid grid-cols-4 gap-3 items-start">
        <div class="col-span-4 xl:col-span-3">
          <ProductDetailVideo v-if="currentProduct.type === 'movie'" />
          <ProductDetailFotosetViewer v-else />
          <ProductDetailActions :is-mobile="isMobile" @purchaseProdcut="initProductPurchase" />
          <ProductDetailDescription :is-mobile="isMobile" />
          <ProductDetailBundles @purchaseBundle="initBundlePurchase" />
          <ProductDetailPreviewImages :is-mobile="isMobile" />
        </div>
        <ProductDetailModels />      
      </div>
    </section>
    <div 
      v-else-if="currentProduct.editing || 
        currentProduct.status === 'processing' && 
        currentProduct.type !== 'bundle'" 
      class="flex items-center justify-center mt-24"
    >
      <div>
        {{ $t('productIsProcessing' ) }}
      </div>
    </div>
    <div v-else-if="currentProduct.type === 'bundle'">
      <SeperationBox>
        <template>
          <span v-if="!isMobile">
            {{ $t('productDetails') }}
          </span>
          <span v-else>
            {{ currentProduct.title }}
          </span>
        </template>
      </SeperationBox>
      <ProductDetailBundlesItem 
        :bundle="currentProduct" 
        full
        @purchaseBundle="initBundlePurchase"
      />
    </div>
    <Overlay v-model="notEnoughCredits">
      <div class="text-center">
        <div>
          {{ $t('notEnoughCredits') }}
        </div>

        <div class="mt-6">
          <div
            class="uppercase tracking-wide text-xs
                text-text inline-block px-2 py-1
                cursor-pointer"
            @click="notEnoughCredits = false"
          >
            {{ $t('close') }}
          </div>
          <div
            class="uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer ml-3"
            @click="$router.push('/content/add-credits')"
          >
            {{ $t('addCredits') }}
          </div>
        </div>
      </div>
    </Overlay>

    <Overlay v-model="confirmPurchaseOverlay">
      <div class="text-center">
        <div v-if="currentProduct.type !== 'bundle'">
          {{ $t('confirmProductPurchase') }}
        </div>
        <div v-else>
          {{ $t('confirmBundlePurchase') }}
        </div>

        <div class="mt-6">
          <div
            class="uppercase tracking-wide text-xs 
                inline-block px-2 py-1
                cursor-pointer"
            @click="confirmPurchaseOverlay = false"
          >
            {{ $t('abort') }}
          </div>
      
          <div
            class="uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer ml-3"
            @click="confirmPurchase"
          >
            {{ $t('buyNow') }}
          </div>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
/* eslint-disable no-undef */

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { OPEN_PRODUCT } from '@/store/storeTypes/mutationTypes';
import {
  SeperationBox,
  ProductDetailDescription,
  ProductDetailActions,
  ProductDetailPreviewImages,
  ProductDetailModels,
  ProductDetailVideo,
  ProductDetailBundles,
  ProductDetailFotosetViewer,
  ProductDetailBundlesItem,
} from '@/ui/components';

export default {
  components: {
    SeperationBox,
    ProductDetailDescription,
    ProductDetailActions,
    ProductDetailPreviewImages,
    ProductDetailModels,
    ProductDetailVideo,
    ProductDetailBundles,
    ProductDetailFotosetViewer,
    ProductDetailBundlesItem,
  },

  data() {
    return {
      confirmPurchaseOverlay: false,
      totalBundlePrice: null,
      notEnoughCredits: false,
      selectedBundleToPurchase: null,
    };
  },

  computed: {
    ...mapGetters([
      'currentProduct',
      'productsLoading',
      'currentBreakpoint',
      'currentUser',
      'totalCredits',
      'spentCredits',
      'activeSubscription',
    ]),
    isMobile() {
      return ['xs', 'sm', 'md'].some((bp) => bp === this.currentBreakpoint);
    },
  },

  watch: {
    $route: {
      immediate: true,
      async handler(newVal) {
        const id = Number(newVal.params.id);
        this[OPEN_PRODUCT](id);
        this.getProductForDetailPage(id);
      },
    },
  },

  methods: {
    ...mapMutations([OPEN_PRODUCT]),
    ...mapActions(['getProduct', 'purchaseProdcut', 'purchaseBundle']),
    getProductForDetailPage(id) {
      this.getProduct({
        id: id || this.currentProduct.id,
        params: {
          query: {
            $eager: {
              models: true,
              files: {
                types: true,
              },
              translations: true,
              favorites: true,
              purchases: true,
              bundles: {
                bundleProducts: {
                  purchases: true,
                  files: {
                    types: true,
                  },
                },
              },
              bundleProducts: {
                purchases: true,
                files: {
                  types: true,
                },
              },
            },
          },
        },
      });
    },

    getCreditDiscount(credits) {
      if (
        this.activeSubscription &&
        this.activeSubscription.subscriptionPlan &&
        this.activeSubscription.subscriptionPlan.productDiscount
      ) {
        return (
          credits -
          Math.ceil((this.credits * this.activeSubscription.subscriptionPlan.productDiscount) / 100)
        );
      }
      return credits;
    },

    initProductPurchase() {
      if (this.currentUser.role === 'verifiedUser') {
        this.$router.push('/verify-age');
      }

      const credits = this.getCreditDiscount(this.currentProduct.credits);

      if (credits > this.totalCredits) {
        this.notEnoughCredits = true;
      } else {
        this.confirmPurchaseOverlay = true;
      }
    },

    initBundlePurchase(payload) {
      if (this.currentUser.role === 'verifiedUser') {
        this.$router.push('/verify-age');
      }

      const { totalPriceWithDiscount, bundle } = payload;
      if (totalPriceWithDiscount > this.totalCredits) {
        this.notEnoughCredits = true;
      } else {
        this.confirmPurchaseOverlay = true;
        this.totalBundlePrice = totalPriceWithDiscount;
        this.selectedBundleToPurchase = bundle;
      }
    },

    purchasedProduct(product) {
      return product.purchases.some((purchases) => purchases.userId === this.currentUser.id);
    },

    async confirmPurchase() {
      let totalPrice = 0;
      if (!this.selectedBundleToPurchase) {
        totalPrice = this.currentProduct.credits;
        if (
          this.activeSubscription &&
          this.activeSubscription.subscriptionPlan &&
          this.activeSubscription.subscriptionPlan.productDiscount
        ) {
          totalPrice -= Math.ceil(
            (totalPrice * this.activeSubscription.subscriptionPlan.productDiscount) / 100,
          );
        }
      } else {
        totalPrice = this.totalBundlePrice;
      }

      let freeCreditsSpent = 0;
      if (this.creditsSpent < 100) {
        const freeCreditsLeft = 100 - this.creditsSpent;
        const newTotalPrice = totalPrice - freeCreditsLeft;
        if (newTotalPrice > 0) {
          freeCreditsSpent = freeCreditsLeft;
          totalPrice = newTotalPrice;
        } else {
          const freeCreditsLeftOver = freeCreditsLeft - totalPrice;
          freeCreditsSpent = freeCreditsLeft - freeCreditsLeftOver;
          totalPrice = 0;
        }
      }

      const chargedCreditsSpent = totalPrice;

      if (!this.selectedBundleToPurchase) {
        await this.purchaseProdcut(this.currentProduct.id);
        this.getProductForDetailPage();
      } else {
        await this.purchaseBundle({
          bundleId: this.selectedBundleToPurchase.id,
        });
        this.$router.push('/products/my');
      }

      this.confirmPurchaseOverlay = false;
      this.selectedBundleToPurchase = null;
      this.totalBundlePrice = null;

      const apiCallsFreeCredits = Array(freeCreditsSpent).fill('');
      const apiCallsSpentCredits = Array(chargedCreditsSpent).fill('');
      const data = {
        props: {
          ID: this.currentProduct.id,
          Title: this.currentProduct.title,
        },
      };
      apiCallsFreeCredits.forEach(() => plausible('Products Credits Spent (Free)', data));
      apiCallsSpentCredits.forEach(() => plausible('Products Credits Spent (Paid)', data));

      plausible('Products Purchases', data);
    },
  },
};
</script>
