<template>
  <section>
    <News />
    <RecentUpdates />
  </section>
</template>

<script>
import { RecentUpdates, News } from '@/ui/components';

export default {
  components: {
    RecentUpdates,
    News,
  },
};
</script>
