<template>
  <div>
    <div v-if="loading">
      <LoadingCircle :custom-text="true">
        <span class="text-sm">{{ $t('verifyingEmail') }}</span>
      </LoadingCircle>
    </div>
    <div v-else>
      <div v-if="error">
        <div class="text-red-600 text-xs">{{ $t('failedEmailVerification') }}</div>
      </div>
      <div v-else>
        <div class="text-green-600 text-xs">
          {{ $t('emailVerified') }}
        </div>

        <router-link
          v-if="currentUser"
          to="/products"
          
          class="mt-3 uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer"
        >
          {{ $t('goToApp' ) }}
        </router-link>
        <router-link
          v-else
          to="/login"
          
          class="mt-3 uppercase tracking-wide text-xs bg-akzent 
                text-background inline-block px-2 py-1 rounded
                cursor-pointer"
        >
          {{ $t('goToLogin') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { accountsApi } from '@/api';
import { mapGetters, mapMutations } from 'vuex';
import { SET_CURRENT_USER } from '@/store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      loading: true,
      error: false,
      fieldStyles: 'pb-3',
      labelStyles: 'pl-2 block uppercase text-xxs tracking-wide',
      inputStyles: 'w-full bg-text py-1 pl-2 text-black text-base lg:text-xs',
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        try {
          await accountsApi.verifyEmail(this.$route.params.token);
          if (this.currentUser) {
            this[SET_CURRENT_USER]({
              ...this.currentUser,
              role: 'verifiedUser',
            });
          }
        } catch (err) {
          if (err.name !== 'AlreadyVerified') {
            this.error = true;
          }
        }

        this.loading = false;
      },
    },
  },

  methods: {
    ...mapMutations([SET_CURRENT_USER]),
  },
};
</script>
